
.results[data-v-3b5e396f]{
    border: 1px solid #ebebeb;
    padding-bottom: 5px;
}
.searchItem[data-v-3b5e396f]{
    margin-left: 20px;
    margin-right: 20px;
    min-height: 30px;
    line-height: 30px;
    border-bottom: 1px solid black;
}
.searchItem[data-v-3b5e396f]:hover{
    background-color: #ebebeb;
    cursor: pointer;
}
.searchBox[data-v-3b5e396f]{
    padding-bottom: 5px;
}
.radio-items[data-v-3b5e396f]{
    display: flex;
}
.radio-item[data-v-3b5e396f]{
    margin-right: 20px;
}
.fr-btn[data-v-3b5e396f]:hover{
    cursor: pointer;
}

