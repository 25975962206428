
.fr-btn[data-v-38401ba2]:hover{
    cursor: pointer;
}
.radio-items[data-v-38401ba2]{
    display: flex;
}
.radio-item[data-v-38401ba2]{
    margin-right: 20px;
}
.footer-info[data-v-38401ba2]{
    font-size: 12px;
    font-style: italic;
}
