
.card[data-v-4e01eaaa]{
  width: 80%;
  min-height: 150px;
  margin: auto;
  border: 1px solid #ebebeb;
  border-bottom: 2px solid #3557A2;
  margin-bottom: 20px;
  font-family: Marianne, arial, sans serif;
  text-align: left;
  padding-left: 20px;
  cursor: pointer;
}
.card[data-v-4e01eaaa]:hover{
  background-color: #EBEBEB;
}
.title-card[data-v-4e01eaaa]{
  font-size: 20px;
  padding-top: 10px;
  font-weight: bold;
}
.content-card[data-v-4e01eaaa]{
  font-size: 17px;
  margin-top: 20px;
}
.search-results[data-v-4e01eaaa]{
  width: 80%;
  min-height: 80px;
  border: 1px solid black;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
}
.ariane[data-v-4e01eaaa]{
  font-weight: bold;
  font-style: italic;
  color: #3557A2;
  cursor: pointer;
  padding: 4px;
  padding-left: 0px;
  font-size: 12px;
}
.ariane[data-v-4e01eaaa]:hover{
  background-color: #ebebeb;
}

