
.header[data-v-0259c78f] {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-family: Marianne, Arial, Helvetica, sans-serif;
  font-size: 12px;
  justify-content: space-between;
}
.item-header[data-v-0259c78f] {
  margin-right: 20px;
  line-height: 60px;
  vertical-align: middle;
}
.item-subpart[data-v-0259c78f] {
  line-height: 50px;
  font-size: 1rem;
}

/* Hide item-subpart on small screens */
@media (max-width: 768px) {
.item-subpart[data-v-0259c78f] {
    display: none;
}
}
